import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Typography from '../components/Typography/Typography'
import { Input, Button, Chip } from '@material-tailwind/react'
import { SocialCard } from '../components/SocialReplies/Card'
import SearchIcon from '../icons/search.svg'
import { Selector } from '../components/SocialReplies/Select'
import InstagramIcon from '@material-ui/icons/Instagram'
import { Card } from '@material-tailwind/react'
import { useTranslation } from 'react-i18next'
import facebook from '../icons/facebook.svg'
import twitter from '../icons/twitter.svg'
import youtube from '../icons/youtube.svg'
import linkedin from '../icons/linkedin.svg'
import pinterest from '../icons/pinterest.svg'
import tiktok from '../icons/tiktok.svg'
import refreshIcon from '../icons/refresh-icon.svg'
import firebase from 'firebase/compat/app'
import store, { useAppDispatch, useAppSelector } from '../store/store'
import redditIcon from '../icons/reddit.svg'
import '../css/social-replies.css'
import {
  cleanupMentionsSubscriptions,
  fetchMentions,
  setAllMentionLoading,
  setAllMentions,
  updateMentionsById,
  updateMentionSubCollection,
} from '../store/features/mentionSlice'
import {
  removeKeyword,
  addNegativeKeyword,
  removeNegativeKeyword,
  setCurrentProject,
  addOrUpdateKeyword,
  updateProjectKeywordStatus,
} from '../store/features/projectSlice'
import CustomTooltip from '../components/Tooltip/Tooltip'
import Toast from '../components/Toast/Toast'
import { IMention } from '../store/features/mentionSlice'
import SocialTabs from '../components/SocialReplies/SocialTabs'
import { getCustomTokenFromServer } from '../utils/getCustomTokenFromServer'
import { customEvent } from '../utils/customHooks'
import { setShowUpgradeModal } from '../store/features/userSlice'
import { fetchAllAccounts } from '../store/features/accountSlice'
import { getCookieByKey } from '../utils/getCookieByKey'
import CustomIconButton from '../components/IconButton/IconButton'
import { autoGenerateReply } from '../utils/autoGenerateReply'
import { FilterBarsIcon } from '../components/SvgIcons'
import SplashScreen from '../components/SplashScreen'
import { capitalizeFirstLetterOfEachWord } from '../utils/capitalizeFirstLetterOfEachWord'
import { filterOptions, isSameDay } from '../utils/dateFiltersConstant'
import { MemoizedTableRow } from '../components/SocialReplies/MemizedTableRow'
import {
  addKeywordsToCollection,
  removeKeywordFromCollection,
} from '../store/features/keywordSlice'

interface SocialButtonsState {
  [key: string]: boolean
}

type KeywordChip = string | { id: string; name: string }

type SocialPlatform = 'reddit' | 'twitter' | 'facebook' | 'linkedin'

const SocialReplies = (): JSX.Element => {
  const [filter, setFilter] = useState('All Time')
  const [search, setSearch] = useState('')
  const [activeTab, setActiveTab] = useState('all_mentions')
  const { allMentions, allMentionLoading } = useAppSelector(
    (store) => store.mentions,
  )
  const { currentProject, loading } = useAppSelector((store) => store.project)
  const { currentAccount } = useAppSelector((store) => store.account)
  const { userStatus, user } = useAppSelector((store) => store.user)
  const { plans } = useAppSelector((store) => store.plan)

  const hasAutoReply = currentAccount?.features?.hasAutoReply

  const allowedKeywords = useMemo(() => {
    if (plans.length > 0 && userStatus) {
      const userPlan = plans.find(
        (plan) => plan?.planID === userStatus?.plan?.product,
      )
      const allowedKeywords = parseInt(
        userPlan?.stripe_metadata_socialMonitoringAllowedKeywords || '1',
        10,
      )
      return allowedKeywords
    }
    return 1
  }, [plans, userStatus])

  const isFreePlan = useMemo(() => {
    if (plans.length > 0 && userStatus) {
      const userPlan = plans.find(
        (plan) => plan?.planID === userStatus?.plan?.product,
      )
      return userPlan?.stripe_metadata_isFreePlan !== 'false'
    }
    return true
  }, [plans, userStatus])

  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const [keyword, setKeyword] = useState('')
  const [reply, setReply] = useState(false)
  const [negativeKeywords, setnegativeKeywords] = useState('')
  const [keywordLoading, setKeywordLoading] = useState<boolean>(false)
  const [keywordChip, setKeywordChip] = useState<KeywordChip[]>([])
  const [negativeLoading, setNegativeLoading] = useState<boolean>(false)
  const [negativeKeywordsChip, setnegativeKeywordsChip] = useState<string[]>([])
  // const [checkedItems, setCheckedItems] = useState<string[]>([])
  const [allMentionsDocs, setAllMentionsDocs] = useState<IMention[]>([])
  const [lastWeekMention, setLastWeekMention] = useState(0)
  const [socialButtonState, setSocialButtonState] =
    useState<SocialButtonsState>({
      reddit: true,
      facebook: true,
      twitter: true,
      linkedin: true,
      instagram: true,
    })

  const [editableContent, setEditableContent] = useState('')
  const [editingIndex, setEditingIndex] = useState<number | null>(null)
  const textAreaRef = useRef<HTMLDivElement | null>(null)

  const handleContentChange = useCallback((value: string): void => {
    // console.log('Editable content:', value)
    setEditableContent(value)
  }, [])

  const handleEditClick = useCallback(
    (index: number | null, content: string): void => {
      setEditingIndex(index)
      setEditableContent(content)
    },
    [],
  )
  const handleSave = useCallback(
    async (index: number, value: IMention): Promise<void> => {
      try {
        const data = {
          id: value.id,
          projectId: currentProject.id,
          sub: {
            ...value.sub,
            autoReply: {
              ...value.sub?.autoReply,
              replyContent: [editableContent],
            },
          },
        }
        dispatch(updateMentionSubCollection(data))
        setEditingIndex(null)
        setEditableContent('')
      } catch (error) {
        console.error('Error saving content:', error)
      }
    },
    [currentProject.id, dispatch, editableContent],
  )

  const handleUpgrade = useCallback((): void => {
    customEvent('billing_upgrade_modal_opened', {
      category: 'accounts',
      accountId: currentAccount.id,
      user_userId: user.uid,
      projectId: currentProject?.id,
      actionSource: 'projects-limit-reached',
    })
    dispatch(setShowUpgradeModal(true))
  }, [currentAccount.id, user.uid, currentProject?.id, dispatch])

  const [syncLoading, setSyncLoading] = useState(false)
  const [isDisabled, setIsDisabled] = useState(true)
  // const [setDisabled, setSetDisabled] = useState(false)

  const handleFilterChange = useCallback(
    (value: string | undefined): void => {
      if (value) {
        setFilter(value)

        const filteredDocs = allMentions.filter(
          (item) => socialButtonState[item.platform as SocialPlatform],
        )

        const oneDayInMs = 24 * 60 * 60 * 1000

        const today = new Date()
        const yesterday = new Date(today.getTime() - oneDayInMs)
        const lastWeek = new Date(today.getTime() - 7 * oneDayInMs)
        const last30Days = new Date(today.getTime() - 30 * oneDayInMs)
        const last90Days = new Date(today.getTime() - 90 * oneDayInMs)

        switch (value) {
          case 'All Time':
            setAllMentionsDocs(filteredDocs)
            break
          case 'Today':
            setAllMentionsDocs(
              filteredDocs.filter((item: { createdAt: { seconds: number } }) =>
                isSameDay(today, item.createdAt.seconds * 1000),
              ),
            )
            break
          case 'Yesterday':
            setAllMentionsDocs(
              filteredDocs.filter((item) =>
                isSameDay(yesterday, item.createdAt.seconds * 1000),
              ),
            )
            break
          case 'Last 7 days':
            setAllMentionsDocs(
              filteredDocs.filter(
                (item) => item.createdAt.seconds * 1000 >= lastWeek.getTime(),
              ),
            )
            break
          case 'Last 30 days':
            setAllMentionsDocs(
              filteredDocs.filter(
                (item) => item.createdAt.seconds * 1000 >= last30Days.getTime(),
              ),
            )
            break
          case 'Last 90 days':
            setAllMentionsDocs(
              filteredDocs.filter(
                (item) => item.createdAt.seconds * 1000 >= last90Days.getTime(),
              ),
            )
            break
          default:
            setAllMentionsDocs(filteredDocs)
            break
        }
      }
    },
    [allMentions, socialButtonState],
  )

  const handlePlatformFilter = useCallback(
    (platform: string): void => {
      setSocialButtonState((prevState) => ({
        ...prevState,
        [platform]: prevState[platform] ? false : true,
      }))
      handleFilterChange(filter)
    },
    [filter, handleFilterChange],
  )

  const _getLastWeekCount = useCallback(() => {
    const oneWeekInMs = 7 * 24 * 60 * 60 * 1000
    const currentTime = Date.now()

    const lastWeekData = allMentions.filter((item) => {
      const itemTime =
        item.createdAt.seconds * 1000 + item.createdAt.nanoseconds / 1000000
      return currentTime - itemTime <= oneWeekInMs
    })
    setLastWeekMention(lastWeekData?.length)
  }, [allMentions])

  useEffect(() => {
    if (allMentions?.length) {
      handleFilterChange(filter)
      _getLastWeekCount()
    } else {
      setLastWeekMention(0)
    }
  }, [
    _getLastWeekCount,
    allMentions,
    filter,
    handleFilterChange,
    socialButtonState,
  ])

  useEffect(() => {
    const inviteId = localStorage.getItem('inviteId')
    const inviteValidityCode = localStorage.getItem('inviteValidityCode')

    const acceptInviteAndProceed = async (): Promise<void> => {
      const token = await firebase.auth().currentUser?.getIdToken()
      const response = await fetch(
        `${process.env.REACT_APP_FIREBASE_API}/accounts/acceptInvite`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            data: {
              inviteId,
              validityCode: parseInt(inviteValidityCode!, 10),
            },
          }),
        },
      )
      const data = await response.json()
      if (data && data.status === 'success') {
        Toast({
          title: t('accountSettings.inviteAccepted'),
          text: t('accountSettings.accountJoined'),
          variant: 'success',
          options: { autoClose: 5000 },
        })
        localStorage.removeItem('inviteId')
        localStorage.removeItem('inviteValidityCode')
        dispatch(fetchAllAccounts())
      }
    }

    if (inviteId && inviteValidityCode && user) {
      acceptInviteAndProceed()
    }
  }, [user])

  const handleAddKeyword = useCallback(async (): Promise<void> => {
    try {
      setKeywordLoading(true)
      Toast({
        title: 'Fetching Posts',
        text: "Please sit back and relax, we're fetching posts for this keyword. This may take a few minutes.",
        variant: 'success',
        options: { autoClose: 5000 },
      })
      const keywordId = await addKeywordsToCollection(
        currentProject.id,
        keyword,
      )
      const keywordData = {
        id: keywordId,
        name: keyword,
      }
      await addOrUpdateKeyword(currentProject.id, keywordData)
      if (!currentProject.keywords) {
        console.log('update status pending keywords not present')
        await updateProjectKeywordStatus(currentProject.id, 'pending')
      } else if (currentProject.keywords.length === 0) {
        console.log('update status pending')
        await updateProjectKeywordStatus(currentProject.id, 'pending')
      }

      customEvent('monitor_keyword_added', {
        category: 'social_monitoring',
        accountId: currentProject?.accountId,
        projectId: currentProject?.id,
        keyword: keyword,
        actionMetadata: 'positive-keyword',
      })
      setKeyword('')
      dispatch(setAllMentionLoading('idle'))
    } catch (error) {
      setKeywordLoading(false)
      console.error('error', error)
      Toast({
        title: 'Please Try again',
        text: 'Error while adding keyword',
        variant: 'error',
      })
    }
  }, [
    currentProject?.accountId,
    currentProject.id,
    currentProject.keywords,
    dispatch,
    keyword,
  ])

  const handleSync = useCallback(async (): Promise<void> => {
    setSyncLoading(true)
    try {
      const payload = {
        platform: ['linkedin', 'facebook', 'reddit', 'twitter', 'instagram'],
        account: [currentAccount.id],
      }

      const token = await firebase.auth().currentUser?.getIdToken()
      const response = await fetch(
        `${process.env.REACT_APP_FIREBASE_API}/updatePosts`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(payload),
        },
      )
      const data = await response.text()
      console.log('Response:', data)

      if (response.status === 200) {
        console.log('Mentions Synced', currentProject)

        dispatch(setAllMentions([]))
        dispatch(setAllMentionLoading('idle'))
        dispatch(
          fetchMentions({
            projectId: currentProject.id,
            keywords: currentProject.keywords.map((keyword) =>
              typeof keyword === 'string' ? keyword : keyword.name,
            ),
          }),
        )
        setIsDisabled(true)
        // setSetDisabled(true)
        const currentMilliseconds = Date.now()
        const currentSeconds = Math.floor(currentMilliseconds / 1000)
        const seconds = currentSeconds.toString().padStart(10, '0')
        const performanceTime = performance.now()
        const currentNanoseconds = Math.floor(performanceTime * 1000000)
        const nanoseconds = Math.floor(currentNanoseconds % 1000000000)
        const nano = nanoseconds.toString().padStart(9, '0')

        const time = {
          seconds: parseInt(seconds, 10),
          nanoseconds: parseInt(nano, 10),
        }

        dispatch(
          setCurrentProject({
            ...currentProject,
            lastDataFetchedAt: time,
          }),
        )
      }

      setSyncLoading(false)

      customEvent('mentions_sync_data_manually', {
        projectId: currentProject?.id,
        accountId: currentProject?.accountId,
        user_userId: user.uid,
      })
    } catch (error) {
      console.error('error', error)
      setSyncLoading(false)
    }
  }, [currentAccount.id, currentProject, dispatch, user.uid])

  useEffect(() => {
    if (currentProject.id && currentProject.keywords) {
      const data = {
        projectId: currentProject?.id,
        keywords: currentProject?.keywords.map((keyword) =>
          typeof keyword === 'string' ? keyword : keyword?.name,
        ),
      }

      console.log('Fetching Mentions:', data)
      dispatch(fetchMentions(data))

      // Monitor state updates
      const unsubscribe = store.subscribe(() => {})

      return () => {
        cleanupMentionsSubscriptions()
        unsubscribe()
      }
    } else {
      console.log('No project id or keywords')
      dispatch(setAllMentions([]))
      dispatch(setAllMentionLoading('succeeded'))
    }
  }, [currentProject?.id, currentProject?.keywords])

  useEffect(() => {
    if (currentProject && loading !== 'idle') {
      if (currentProject?.lastDataFetchedAt) {
        const lastDataFetchedAt = new Date(
          currentProject.lastDataFetchedAt.seconds * 1000,
        )
        const currentDate = new Date()
        const timeDifference = Math.floor(
          (currentDate.getTime() - (lastDataFetchedAt?.getTime() || 0)) /
            (1000 * 60),
        )
        if (!isFreePlan && timeDifference > 60) {
          setIsDisabled(false)
        } else if (timeDifference < 24 * 60) {
          setIsDisabled(true)
        } else {
          setIsDisabled(false)
        }
      } else {
        setIsDisabled(false)
      }
    }
    if (keywordChip?.length === 0) {
      setIsDisabled(true)
    }
  }, [
    currentProject,
    isDisabled,
    isFreePlan,
    keywordChip?.length,
    loading,
    currentProject?.lastDataFetchedAt,
  ])

  const _checkKeywords = useCallback(
    (keywordsArr: string[], value: string): boolean => {
      const lowerCaseKeywords = keywordsArr.map((item) => item.toLowerCase())
      const lowerCaseValue = value.toLowerCase()
      return lowerCaseKeywords.includes(lowerCaseValue)
    },
    [],
  )

  useEffect(() => {
    const sendDataToChrome = async (): Promise<void> => {
      const token = await getCustomTokenFromServer()
      window.postMessage(
        {
          token: token,
          accountId: currentProject.accountId,
          projectId: currentProject.id,
        },
        '*',
      )
      if (document?.cookie) {
        const ga4ClientId = getCookieByKey('_ga')
        if (ga4ClientId) {
          const parts = ga4ClientId?.split('.')
          const extractedString = parts?.slice(2).join('.')
          window.postMessage({ ga4ClientId: extractedString }, '*')
        }
      }
    }
    sendDataToChrome()
  }, [currentProject.accountId, currentProject.id])

  const onSearch = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const value = event.target?.value?.toLowerCase()
      setSearch(event.target?.value)
      if (value?.length > 3) {
        const filteredMention = allMentionsDocs?.filter((item) => {
          return (
            item.contentTitle?.toLocaleLowerCase()?.includes(value) ||
            item.contentText?.toLocaleLowerCase()?.includes(value) ||
            item.user?.toLocaleLowerCase()?.includes(value) ||
            _checkKeywords(item.keywords, value)
          )
        })
        const filteredDocs = filteredMention.filter(
          (item) => socialButtonState[item.platform as SocialPlatform],
        )
        setAllMentionsDocs(filteredDocs)
      } else {
        handleFilterChange(filter)
      }
    },
    [allMentionsDocs, filter, socialButtonState],
  )

  useEffect(() => {
    if (Object.keys(currentProject).length > 0) {
      setKeywordChip(currentProject.keywords ? currentProject.keywords : [])
      setActiveTab(
        currentProject.keywords?.length > 0 ? 'all_mentions' : 'keywords',
      )
      const tabsValue =
        currentProject.keywords?.length > 0 ? 'all_mentions' : 'keywords'
      const socialTabs = document.getElementById(`social-tabs-${tabsValue}`)
      if (socialTabs) {
        socialTabs.click()
      }
    }
  }, [currentProject])

  useEffect(() => {
    if (Object.keys(currentProject).length > 0) {
      setnegativeKeywordsChip(
        currentProject.negativeKeywords ? currentProject.negativeKeywords : [],
      )
    }
  }, [currentProject])

  useEffect(() => {
    const updateStatus = async (): Promise<void> => {
      if (currentProject?.keywordFetchStatus === 'completed') {
        console.log('update status completed')
        setKeywordLoading(false)
      } else if (currentProject?.keywordFetchStatus === 'pending') {
        console.log('update status pending')
        setKeywordLoading(true)
      } else {
        setKeywordLoading(false)
      }
    }
    updateStatus()
  }, [currentProject?.keywordFetchStatus])

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const userKeyword = event.target.value
      console.log('User Keyword:', userKeyword.toLowerCase())
      setKeyword(userKeyword.toLowerCase())
    },
    [],
  )

  const onChangeNegativeKeywords = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
      setnegativeKeywords(event.target.value)
    },
    [],
  )

  const handleClose = useCallback(
    async (itemToRemove: string): Promise<void> => {
      let keywordRemove: KeywordChip = {
        id: '',
        name: '',
      }
      currentProject?.keywords.map((item) => {
        if (typeof item === 'string') {
          keywordRemove = itemToRemove
        } else {
          if (item.name === itemToRemove) {
            keywordRemove = item
          }
        }
      })
      if (keywordRemove) {
        await removeKeyword(currentProject.id, keywordRemove)
        if (keywordRemove.id) {
          await removeKeywordFromCollection(keywordRemove.id)
        }
        setKeywordChip(
          keywordChip.filter((item) =>
            typeof item === 'string'
              ? item !== itemToRemove
              : item.name !== itemToRemove,
          ),
        )
      }
    },
    [currentProject.id, currentProject?.keywords, keywordChip],
  )

  const handleCloseNegative = useCallback(
    async (itemToRemove: string): Promise<void> => {
      await removeNegativeKeyword(currentProject.id, itemToRemove)
      setnegativeKeywordsChip(
        negativeKeywordsChip.filter((item) => item !== itemToRemove),
      )
    },
    [currentProject.id, negativeKeywordsChip],
  )

  const handleAddNegativeKeyword = useCallback(
    async (itemToAdd: string): Promise<void> => {
      customEvent('monitor_keyword_added', {
        category: 'social_monitoring',
        accountId: currentProject?.accountId,
        projectId: currentProject?.id,
        user_userId: user.uid,
        keyword: itemToAdd,
        actionMetadata: 'negative-keyword',
      })

      setNegativeLoading(true)
      await addNegativeKeyword(currentProject.id, itemToAdd)
      setnegativeKeywords('')
      setnegativeKeywordsChip((prevKeywords) => [...prevKeywords, itemToAdd])
      setNegativeLoading(false)
    },
    [currentProject?.accountId, currentProject?.id, user.uid],
  )

  const REPLIES_COLUMNS = [
    {
      title: 'Site',
      field: 'name',
      width: '5%',
    },
    {
      title: 'Mention',
      field: 'Mention',
      width: '25%',
    },
    {
      title: t && t('transcripts.keywords'),
      field: 'keywords',
      width: '15%',
    },
    {
      title: 'Relevancy Score',
      field: 'relevancyScore',
      width: '20%',
    },
    {
      title: t && t('transcripts.status'),
      field: 'status',
      width: '15%',
    },
    {
      title: 'Date',
      field: 'Date',
      width: '15%',
    },
    {
      title: 'Actions',
      field: 'Actions',
      width: '10%',
    },
  ]

  // if (hasAutoReply) {
  //   REPLIES_COLUMNS.push({
  //     title: 'Reply',
  //     field: 'reply',
  //     width: '10%',
  //   })
  // }

  const handleNavigate = (
    url: string,
    platform?: string,
    userId?: string,
    postId?: string,
  ): void => {
    customEvent('mention_opened', {
      category: 'social_monitoring',
      accountId: currentProject?.accountId,
      projectId: currentProject?.id,
      user_userId: user.uid,
      mentionId: postId,
      actionMetadata: 'within-web-app',
    })
    if (platform === 'reddit') {
      window.postMessage(
        { webRedirect: true, projectId: currentProject?.id, mentionId: postId },
        '*',
      )
      window.open(`${url}`, '_blank')
    } else if (platform === 'twitter') {
      window.postMessage(
        { webRedirect: true, projectId: currentProject?.id, mentionId: postId },
        '*',
      )
      window.open(`https://x.com/${userId}/status/${url}`, '_blank')
    } else if (platform === 'linkedin') {
      window.postMessage(
        { webRedirect: true, projectId: currentProject?.id, mentionId: url },
        '*',
      )
      window.open(
        `https://linkedin.com/feed/update/urn:li:activity:${url}`,
        '_blank',
      )
    } else if (platform === 'facebook') {
      window.postMessage(
        { webRedirect: true, projectId: currentProject?.id, mentionId: postId },
        '*',
      )
      window.open(`https://facebook.com${url}`, '_blank')
    } else if (platform === 'instagram') {
      window.postMessage({
        webRedirect: true,
        projectId: currentProject?.id,
        mentionId: postId,
      })
      window.open(url, '_blank')
    } else {
      window.open(url, '_blank')
    }
  }

  // function for rendering status icon
  const renderStatusIcon = useCallback((status: string) => {
    switch (status) {
      case 'reddit':
        return (
          <div className="rounded-full w-6 h-6 bg-black">
            <img src={redditIcon} alt="" />
          </div>
        )
      case 'twitter':
        return (
          <div className="rounded-full w-6 h-6 bg-black flex justify-center items-center">
            <img src={twitter} alt="" />
          </div>
        )
      case 'facebook':
        return (
          <div className="flex justify-center items-center rounded-full w-6 h-6 bg-[#35518D]">
            <img src={facebook} alt="" />
          </div>
        )
      case 'linkedin':
        return (
          <div className="flex justify-center items-center rounded-full w-6 h-6 bg-[#0A66C2]">
            <img src={linkedin} alt="" />
          </div>
        )
      case 'youtube':
        return (
          <div className="rounded-full w-6 h-6 bg-black">
            <img src={youtube} alt="" />
          </div>
        )
      case 'pinterest':
        return (
          <div className="rounded-full w-6 h-6 bg-black">
            <img src={pinterest} alt="" />
          </div>
        )
      case 'instagram':
        return (
          <div className="flex justify-center items-center rounded-full w-8 h-8 bg-gradient-to-tr from-[#6b4ae7] via-[#f33d9c] to-orange-500 instaIcon">
            <InstagramIcon className="text-white mx-auto" />
          </div>
        )
      case 'tiktok':
        return (
          <div className="rounded-full w-6 h-6 bg-black">
            <img src={tiktok} alt="" />
          </div>
        )
      default:
        return null
    }
  }, [])

  const handleKeywordsTab = useCallback(() => {
    const element: HTMLElement | null = document.querySelector(
      'li[data-value="keywords"]',
    )
    element?.click()
  }, [])

  const handleTooltipContent = useCallback(
    (platform: string): string => {
      const isActive = socialButtonState[platform]
      const socialPlatform =
        platform.charAt(0).toLocaleUpperCase() + platform.slice(1)
      return `${isActive ? 'Hide ' : 'Show '} ${socialPlatform} mentions`
    },
    [socialButtonState],
  )

  const handleMenuItem = useCallback(
    async (id: string): Promise<void> => {
      Toast({
        title: t && t('socialReplies.mentions.hideToast.title'),
        text: t && t('socialReplies.mentions.hideToast.text'),
        variant: 'success',
      })
      const data = {
        id,
        projectId: currentProject.id,
      }
      dispatch(updateMentionsById(data))
      customEvent('mention_hidden', {
        accountId: currentAccount.id,
        projectId: currentProject.id,
        mentionId: id,
        user_userId: user.uid,
      })
    },
    [allMentions, currentAccount.id, currentProject.id, dispatch, user.uid],
  )

  const data = [
    {
      label: 'All mentions',
      value: 'all_mentions',
      desc: (
        <>
          <div className="w-[400px] sm:overflow-x-scroll md:overflow-hidden md:w-full h-auto mx-auto mt-1 pb-8">
            {/* Social Cards */}
            <div className="flex gap-2">
              <SocialCard
                title="Mentions"
                totalMentions={allMentions?.length}
                lastWeek={`Last Week ${lastWeekMention ? lastWeekMention : 0}`}
              />
              <SocialCard
                title="PRO HINT"
                description="Set up Reddit monitoring"
              />
              <SocialCard
                title="AUTOPILOT MODE"
                description="Set up Auto Replies"
              />
              <SocialCard
                title="CHROME EXTENSION"
                description="Write replies, in 1 click"
              />
            </div>
            {/* Search, Filter and Icons */}
            <div className="mt-6 flex justify-between">
              <div className="flex gap-2 grow">
                <div className="relative w-80 rounded-xl">
                  <img
                    src={SearchIcon}
                    className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400"
                  />
                  <Input
                    type="text"
                    size="md"
                    placeholder="Search"
                    value={search}
                    className="pl-10 !border-lstnBlueGray-100 focus:!border-gray-900 rounded-xl text-blue-gray-400 placeholder:opacity-100"
                    onChange={onSearch}
                    labelProps={{
                      className: 'hidden',
                    }}
                    crossOrigin={undefined}
                  />
                </div>
                <Button
                  placeholder={undefined}
                  className="bg-transparent outline-none shadow-none hover:shadow-none px-3"
                >
                  <FilterBarsIcon />
                </Button>

                <Selector
                  options={filterOptions}
                  selectedValue={filter}
                  onChange={handleFilterChange}
                  className="relative text-sm font-medium w-24 border-none h-[1.5rem] filterSelector text-blue-gray-500  hover:text-lstnBlueGray-900 uppercase bg-transparent rounded-lg"
                  children={undefined}
                />
              </div>
              <div className="flex items-center gap-2">
                {/* {isFreePlan && ( */}
                <>
                  {currentProject.lastDataFetchedAt && (
                    <Typography
                      variant="small"
                      className="font-normal text-lstnBlueGray-500 text-xs w-[131px]"
                    >
                      Data last Updated at:{' '}
                      {currentProject.lastDataFetchedAt &&
                        new firebase.firestore.Timestamp(
                          currentProject.lastDataFetchedAt.seconds,
                          currentProject.lastDataFetchedAt.nanoseconds,
                        )
                          .toDate()
                          .toLocaleString('en-US', {
                            day: '2-digit',
                            month: 'short',
                            year: 'numeric',
                            hour: 'numeric',
                            minute: '2-digit',
                            hour12: true,
                          })}
                    </Typography>
                  )}
                  <CustomTooltip
                    content={
                      isDisabled
                        ? isFreePlan
                          ? t('socialReplies.refreshTooltip.disabled')
                          : t('socialReplies.refreshTooltip.premiumDisabled')
                        : isFreePlan
                          ? t('socialReplies.refreshTooltip.enabled')
                          : t('socialReplies.refreshTooltip.premiumEnabled')
                    }
                    className="bg-lstnBlueGray-400 w-56"
                  >
                    <span>
                      <Button
                        placeholder={undefined}
                        loading={syncLoading}
                        disabled={isDisabled}
                        onClick={handleSync}
                        variant="filled"
                        size="sm"
                        className="bg-well_primary-500 text-white capitalize flex"
                      >
                        <img
                          src={refreshIcon}
                          alt="DirectoriesListingLogo"
                          className="w-4 h-4 mr-2"
                        />
                        REFRESH
                      </Button>
                    </span>
                  </CustomTooltip>
                </>
                {/* )} */}
                <CustomTooltip
                  content={handleTooltipContent('reddit')}
                  className="bg-lstnBlueGray-400"
                >
                  <span>
                    <CustomIconButton
                      className={`rounded-full w-8 h-8 bg-[#FF4500] ${
                        !socialButtonState.reddit
                          ? 'focus:shadow-md active:opacity-[0.4] focus:opacity-[0.4] opacity-40 shadow-none'
                          : 'focus:opacity-[1] focus:shadow-md active:opacity-[1] shadow-lg opacity-100'
                      }`}
                      onClick={() => handlePlatformFilter('reddit')}
                    >
                      <img src={redditIcon} alt="" />
                    </CustomIconButton>
                  </span>
                </CustomTooltip>

                <CustomTooltip
                  content={handleTooltipContent('twitter')}
                  className="bg-lstnBlueGray-400"
                >
                  <span>
                    <CustomIconButton
                      className={`rounded-full w-8 h-8 bg-black ${
                        !socialButtonState.twitter
                          ? 'focus:shadow-md active:opacity-[0.4] focus:opacity-[0.4] opacity-40 shadow-none'
                          : 'focus:opacity-[1] focus:shadow-md active:opacity-[1] shadow-lg opacity-100'
                      }`}
                      onClick={() => handlePlatformFilter('twitter')}
                    >
                      <img src={twitter} alt="" />
                    </CustomIconButton>
                  </span>
                </CustomTooltip>

                <CustomTooltip
                  content={handleTooltipContent('facebook')}
                  className="bg-lstnBlueGray-400"
                >
                  <span>
                    <CustomIconButton
                      // disabled={socialButtonState !== 'facebook'}
                      className={`rounded-full w-8 h-8 bg-[#35518D] ${
                        !socialButtonState.facebook
                          ? 'focus:shadow-md active:opacity-[0.4] focus:opacity-[0.4] opacity-40 shadow-none'
                          : 'focus:opacity-[1] focus:shadow-md active:opacity-[1] shadow-lg opacity-100'
                      }`}
                      onClick={() => handlePlatformFilter('facebook')}
                    >
                      <img src={facebook} alt="" />
                    </CustomIconButton>
                  </span>
                </CustomTooltip>

                <CustomTooltip
                  content={handleTooltipContent('instagram')}
                  className="bg-lstnBlueGray-400"
                >
                  <span>
                    <CustomIconButton
                      className={`rounded-full w-8 h-8 bg-gradient-to-tr from-[#6b4ae7] via-[#f33d9c] to-orange-500  ${
                        !socialButtonState.instagram
                          ? 'focus:shadow-md active:opacity-[0.4] focus:opacity-[0.4] opacity-40 shadow-none'
                          : 'focus:opacity-[1] focus:shadow-md active:opacity-[1] shadow-lg opacity-100'
                      }`}
                      onClick={() => handlePlatformFilter('instagram')}
                    >
                      <InstagramIcon className="text-white mx-auto" />
                    </CustomIconButton>
                  </span>
                </CustomTooltip>

                {/* <CustomTooltip
                  content="coming soon"
                  className="bg-lstnBlueGray-400"
                >
                  <span>
                    <IconButton
                      disabled
                      placeholder={undefined}
                      className="rounded-full w-8 h-8 bg-[#E60019]"
                      onClick={() =>
                        handleNavigate('https://www.pinterest.com/')
                      }
                    >
                      <img src={pinterest} alt="" />
                    </IconButton>
                  </span>
                </CustomTooltip>

                <CustomTooltip
                  content="coming soon"
                  className="bg-lstnBlueGray-400"
                >
                  <span>
                    <IconButton
                      disabled
                      placeholder={undefined}
                      className="rounded-full w-8 h-8 bg-black"
                      onClick={() => handleNavigate('https://www.tiktok.com/')}
                    >
                      <img src={tiktok} alt="" />
                    </IconButton>
                  </span>
                </CustomTooltip> */}

                <CustomTooltip
                  content={handleTooltipContent('linkedin')}
                  className="bg-lstnBlueGray-400"
                >
                  <span>
                    <CustomIconButton
                      className={`rounded-full w-8 h-8 bg-[#0A66C2] ${
                        !socialButtonState.linkedin
                          ? 'focus:shadow-md active:opacity-[0.4] focus:opacity-[0.4] opacity-40 shadow-none'
                          : 'focus:opacity-[1] focus:shadow-md active:opacity-[1] shadow-lg opacity-100'
                      }`}
                      onClick={() => handlePlatformFilter('linkedin')}
                    >
                      <img src={linkedin} alt="" />
                    </CustomIconButton>
                  </span>
                </CustomTooltip>

                {/* <CustomTooltip
                  content="coming soon"
                  className="bg-lstnBlueGray-400"
                >
                  <span>
                    <IconButton
                      disabled
                      placeholder={undefined}
                      className="rounded-full w-8 h-8 bg-[#FF0000]"
                      onClick={() => handleNavigate('https://www.youtube.com/')}
                    >
                      <img src={youtube} alt="" />
                    </IconButton>
                  </span>
                </CustomTooltip> */}
              </div>
            </div>
            {/* Mentions Table */}
            <div>
              <Card
                className="h-full w-full mt-6 overflow-hidden border border-blue-gray-50"
                placeholder={undefined}
              >
                <table className="text-left">
                  <thead>
                    <tr>
                      <th
                        className={`border-b border-blue-gray-100 bg-[#F9FAFB] gap-2 h-10`}
                        style={{
                          width: '3%',
                        }}
                      >
                        {/* <Checkbox
                          onClick={(e) => {
                            e.stopPropagation()
                            handleSelectAll()
                          }}
                          checked={selectAllChecked}
                          crossOrigin={undefined}
                        /> */}
                      </th>
                      {REPLIES_COLUMNS.map((val, index) => (
                        <th
                          key={index}
                          className="border-b border-blue-gray-100 bg-[#F9FAFB] gap-2 h-10 text-sm text-blue-gray-900 font-semibold leading-none pr-6"
                          style={{
                            width: val.width, // Set max-width to avoid columns extending beyond container
                          }}
                        >
                          <Typography
                            variant="small"
                            className="font-medium text-lstnBlueGray-900 text-base"
                          >
                            {val.title}
                          </Typography>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {allMentionLoading === 'pending' ||
                    currentProject?.keywordFetchStatus === 'pending' ||
                    allMentionLoading === 'idle' ? (
                      <tr>
                        <td
                          colSpan={REPLIES_COLUMNS.length + 1}
                          className="text-lstnBlueGray-500 text-center w-full h-[350px]"
                        >
                          <div className="max-w-full animate-pulse text-center items-center flex flex-col">
                            <Typography
                              as="div"
                              variant="h1"
                              className="mb-4 h-3 w-56 rounded-full bg-gray-300"
                            >
                              &nbsp;
                            </Typography>
                            <Typography
                              as="div"
                              variant="paragraph"
                              className="mb-2 h-2 w-72 rounded-full bg-gray-300"
                            >
                              &nbsp;
                            </Typography>
                            <Typography
                              as="div"
                              variant="paragraph"
                              className="mb-2 h-2 w-72 rounded-full bg-gray-300"
                            >
                              &nbsp;
                            </Typography>
                            <Typography
                              as="div"
                              variant="paragraph"
                              className="mb-2 h-2 w-72 rounded-full bg-gray-300"
                            >
                              &nbsp;
                            </Typography>
                            <Typography
                              as="div"
                              variant="paragraph"
                              className="mb-2 h-2 w-72 rounded-full bg-gray-300"
                            >
                              &nbsp;
                            </Typography>
                          </div>
                        </td>
                      </tr>
                    ) : !allMentions.length &&
                      allMentionLoading === 'succeeded' ? (
                      <tr>
                        <td
                          colSpan={REPLIES_COLUMNS.length + 1}
                          className="text-lstnBlueGray-500 text-center h-[350px]"
                        >
                          <div className="w-96 mx-auto">
                            <div className="flex justify-center mb-4">
                              <img src="/emptyglass.svg" alt="img" />
                            </div>
                            <Typography className="text-base font-semibold text-lstnBlueGray-900 text-center mb-3">
                              {t('socialReplies.table.emptyMessage')}
                            </Typography>
                            <Typography
                              variant="small"
                              className="text-base font-normal"
                            >
                              {t('socialReplies.table.content')}
                            </Typography>
                            <Typography
                              className="text-sm mt-3 font-medium text-lstnBlue-500 cursor-pointer text-well_primary-500 flex items-center justify-center"
                              onClick={handleKeywordsTab}
                            >
                              <span className="inline-block lstn-btn ml-0 w-3 h-3 p-0 rounded-full text-center relative mr-1 bg-well_primary-500">
                                <svg width="12" height="12" aria-hidden="true">
                                  <use xlinkHref="#icon-pluss" />
                                </svg>
                              </span>
                              {t('socialReplies.table.keyword')}
                            </Typography>
                          </div>
                        </td>
                      </tr>
                    ) : allMentions.length &&
                      !allMentionsDocs.length &&
                      allMentionLoading === 'succeeded' ? (
                      <tr>
                        <td
                          colSpan={REPLIES_COLUMNS.length + 1}
                          className="text-lstnBlueGray-500 text-center h-[350px]"
                        >
                          <div className="w-96 mx-auto">
                            <div className="flex justify-center mb-4">
                              <img src="/emptyFilter.svg" alt="img" />
                            </div>
                            <Typography className="text-base font-semibold text-lstnBlueGray-900 text-center mb-3">
                              {t('socialReplies.table.emptyMessage')}
                            </Typography>
                            <Typography
                              variant="small"
                              className="text-base font-normal"
                            >
                              {t('socialReplies.table.filterMessage')}
                            </Typography>
                          </div>
                        </td>
                      </tr>
                    ) : (
                      allMentionsDocs.map((value, index) => (
                        <MemoizedTableRow
                          key={index}
                          value={value}
                          index={index}
                          handleEditClick={handleEditClick}
                          handleSave={handleSave}
                          editingIndex={editingIndex}
                          editableContent={editableContent}
                          handleContentChange={handleContentChange}
                          textAreaRef={textAreaRef}
                          renderStatusIcon={renderStatusIcon}
                          currentAccount={currentAccount}
                          currentProject={currentProject}
                          handleNavigate={handleNavigate}
                          handleMenuItem={handleMenuItem}
                          autoGenerateReply={autoGenerateReply}
                          reply={reply}
                          setReply={setReply}
                          t={t}
                          hasAutoReply={hasAutoReply}
                        />
                      ))
                    )}
                  </tbody>
                </table>
              </Card>
            </div>
          </div>
        </>
      ),
    },
    // {
    //   label: 'Replies',
    //   value: 'Replies',
    //   desc: (
    //     <>
    //       <div className="w-[400px] md:w-[936px] h-auto mx-auto mt-1">
    //         <Typography className="text-base font-normal text-lstnGray-900 mb-3">
    //           coming soon
    //         </Typography>
    //       </div>
    //     </>
    //   ),
    // },
    {
      label: 'Keywords',
      value: 'keywords',
      desc: (
        <>
          <div className="w-[400px] sm:overflow-x-scroll md:overflow-hidden md:w-full h-auto mx-auto mt-1 pb-8">
            <Typography className="text-xl font-medium text-lstnGray-900 mb-3">
              Keywords to Monitor
            </Typography>
            <div className="space-y-4 w-[400px] md:w-[880px] h-auto mt-1 pb-8">
              <Typography className="text-sm font-normal text-lstnBlueGray-900">
                Add below the list of keywords you want WellShared to monitor.
              </Typography>
              {keywordChip?.length >= allowedKeywords ? (
                <CustomTooltip
                  content="Upgrade to add more keywords"
                  className="bg-lstnBlueGray-400"
                >
                  <div
                    className="relative flex w-full max-w-[18rem]"
                    style={{ marginLeft: '0px', margin: '1rem 0px' }}
                  >
                    <Input
                      placeholder="Type keyword"
                      type="text"
                      value={keyword}
                      className=" !border-lstnBlueGray-100 focus:!border-gray-900 text-blue-gray-400 placeholder:opacity-100"
                      onChange={onChange}
                      labelProps={{
                        className: 'hidden',
                      }}
                      disabled={keywordLoading}
                      crossOrigin={undefined}
                    />
                    <Button
                      placeholder={undefined}
                      loading={keywordLoading}
                      variant="filled"
                      disabled
                      size="sm"
                      color={keyword ? 'gray' : 'blue-gray'}
                      className="!absolute right-1 top-1 rounded bg-well_primary-50 text-well_primary-500"
                    >
                      ADD
                    </Button>
                  </div>
                </CustomTooltip>
              ) : (
                <div
                  className="relative flex w-full max-w-[30rem]"
                  style={{ marginLeft: '0px', margin: '1rem 0px' }}
                >
                  <Input
                    placeholder="Type keyword"
                    type="text"
                    value={capitalizeFirstLetterOfEachWord(keyword)}
                    className=" !border-lstnBlueGray-100 focus:!border-gray-900 text-blue-gray-400 placeholder:opacity-100"
                    onChange={onChange}
                    labelProps={{
                      className: 'hidden',
                    }}
                    disabled={keywordLoading}
                    crossOrigin={undefined}
                  />
                  <Button
                    placeholder={undefined}
                    // onClick={addKeyword}
                    onClick={handleAddKeyword}
                    loading={keywordLoading}
                    disabled={keyword.length < 2}
                    variant="filled"
                    size="sm"
                    color={keyword ? 'gray' : 'blue-gray'}
                    className="!absolute right-1 top-1 rounded bg-well_primary-50 text-well_primary-500"
                  >
                    ADD
                  </Button>
                </div>
              )}
              {currentProject?.suggestedKeywords?.length > 0 ? (
                <div className="flex flex-wrap gap-0">
                  <Typography className="text-sm font-bold text-lstnBlueGray-900 py-1">
                    Suggestions by WellShared:
                  </Typography>
                  {currentProject?.suggestedKeywords?.map(
                    (item: string, index: number) => {
                      return (
                        <Button
                          key={index}
                          className="text-well_primary-500 w-fit capitalize text-sm font-normal px-2 py-1"
                          variant="text"
                          placeholder={'suggestedKeyword'}
                          onClick={() => setKeyword(item.toLowerCase())}
                        >
                          {item}
                        </Button>
                      )
                    },
                  )}
                </div>
              ) : null}
              <div className="my-2 flex gap-2">
                {keywordChip?.map((item, index) => {
                  return (
                    <Chip
                      key={index}
                      open={true}
                      animate={{
                        mount: { y: 0 },
                        unmount: { y: 50 },
                      }}
                      size="sm"
                      className="bg-well_primary-500 text-white w-fit capitalize text-xs font-bold px-2 py-1"
                      value={
                        typeof item === 'string'
                          ? capitalizeFirstLetterOfEachWord(item)
                          : capitalizeFirstLetterOfEachWord(item.name)
                      }
                      onClose={() =>
                        handleClose(typeof item === 'string' ? item : item.name)
                      }
                    />
                  )
                })}
              </div>
              <Typography className="text-sm font-normal text-lstnBlueGray-900 mx-0 my-2">
                Currently using:{' '}
                <span className="font-semibold">
                  {keywordChip?.length} of {allowedKeywords}
                </span>{' '}
                keywords allowed in your plan.{' '}
                <span
                  onClick={handleUpgrade}
                  className="text-well_primary-500 hover:cursor-pointer"
                >
                  Upgrade
                </span>{' '}
                for more keywords.
              </Typography>
            </div>
          </div>
          <div className="w-[400px] sm:overflow-x-scroll md:overflow-hidden md:w-full h-auto mx-auto mt-1 pb-8">
            <Typography className="text-xl font-medium text-lstnGray-900 mb-3">
              Negative Keywords
            </Typography>
            <div className="space-y-4 w-[400px] md:w-[880px] h-auto mt-1 pb-8">
              <Typography className="text-sm font-normal text-lstnBlueGray-900">
                Add below the list of keywords you want WellShared to exclude.
                <br />
                If these keywords are present in any of the mentions, the
                mention will be discarded.
              </Typography>
              <div
                className="relative flex w-full max-w-[18rem]"
                style={{ marginLeft: '0px', margin: '1rem 0px' }}
              >
                <Input
                  placeholder="Type keyword"
                  type="text"
                  value={negativeKeywords}
                  className=" !border-lstnBlueGray-100 focus:!border-gray-900 text-blue-gray-400 placeholder:opacity-100"
                  onChange={onChangeNegativeKeywords}
                  labelProps={{
                    className: 'hidden',
                  }}
                  crossOrigin={undefined}
                />
                <Button
                  placeholder={undefined}
                  variant="filled"
                  onClick={() => handleAddNegativeKeyword(negativeKeywords)}
                  disabled={negativeKeywords.length < 3}
                  loading={negativeLoading}
                  size="sm"
                  color={keyword ? 'gray' : 'blue-gray'}
                  className="!absolute right-1 top-1 rounded bg-well_primary-50 text-well_primary-500"
                >
                  ADD
                </Button>
              </div>
              <div className="my-2 flex gap-2">
                {negativeKeywordsChip.map((item, index) => {
                  return (
                    <Chip
                      key={index}
                      open={true}
                      animate={{
                        mount: { y: 0 },
                        unmount: { y: 50 },
                      }}
                      size="sm"
                      className="bg-well_primary-500 text-white w-fit capitalize text-xs font-bold px-2 py-1"
                      value={item}
                      onClose={() => handleCloseNegative(item)}
                    />
                  )
                })}
              </div>
            </div>
          </div>
        </>
      ),
    },
    // {
    //   label: 'Settings',
    //   value: 'settings',
    //   desc: (
    //     <>
    //       <div className="w-[400px] md:w-[936px] h-auto mx-auto mt-1">
    //         <Typography className="text-base font-normal text-lstnGray-900 mb-3">
    //           coming soon
    //         </Typography>
    //       </div>
    //     </>
    //   ),
    // },
  ]

  if (loading === 'idle') {
    return <SplashScreen />
  }

  return (
    <div className="flex flex-col mt-6 md:container md:mx-auto mx-8 py-4 px-8 sm:overflow-x-scroll md:overflow-hidden">
      <div className="w-[400px] md:w-9/12">
        <Typography className="text-2xl font-medium text-lstnGray-900 my-5">
          Social Replies
        </Typography>
      </div>
      <SocialTabs
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        data={data}
      />
    </div>
  )
}
export default SocialReplies
