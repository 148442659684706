import React, { useState } from 'react'
import navigateIcon from '../../icons/navigateIcon.svg'
import { CheckCircle } from '@material-ui/icons'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import { IMention } from '../../store/features/mentionSlice'
import { IProject } from '../../interfaces'
import Typography from '../Typography/Typography'
import replyIcon from '../../icons/replyIcon.svg'
import { SparklesIcon } from '../SvgIcons'
import { Button, Textarea } from '@material-tailwind/react'
import edit from '../../icons/pencil-edit.svg'
import firebase from 'firebase/compat/app'
import CustomTooltip from '../Tooltip/Tooltip'
import CustomMenu from '../CustomMenu'
import Toast from '../Toast/Toast'
import { useDebounce } from '../../utils/debouncehook'

function areEqual(prevProps: any, nextProps: any): boolean {
  // Custom comparison logic to avoid unnecessary re-renders
  return (
    prevProps.value === nextProps.value &&
    prevProps.editingIndex === nextProps.editingIndex &&
    prevProps.editableContent === nextProps.editableContent
  )
}

interface TableRowProps {
  value: any
  index: number
  handleEditClick: (index: number, content: string) => void
  handleSave: (index: number, value: IMention) => Promise<void>
  editingIndex: null | number
  editableContent: string
  handleContentChange: (e: any) => void
  textAreaRef: any
  renderStatusIcon: (status: string) => React.JSX.Element | null
  currentAccount: any
  handleNavigate: (
    url: string,
    platform?: string,
    userId?: string,
    postId?: string,
  ) => void
  handleMenuItem: (platformContentId: string) => void
  autoGenerateReply: (data: any) => Promise<any>
  reply: boolean
  setReply: (value: boolean) => void
  t: any
  hasAutoReply: boolean | undefined
  currentProject: IProject
}

export const MemoizedTableRow = React.memo(
  ({
    value,
    index,
    handleEditClick,
    handleSave,
    editingIndex,
    editableContent,
    handleContentChange,
    textAreaRef,
    renderStatusIcon,
    currentAccount,
    currentProject,
    handleNavigate,
    handleMenuItem,
    autoGenerateReply,
    reply,
    setReply,
    t,
    hasAutoReply,
  }: TableRowProps) => {
    // console.log('editableContent', editableContent)
    const [localContent, setLocalContent] = useState(
      value.sub?.autoReply?.replyContent[
        value.sub?.autoReply.replyContent.length - 1
      ] || editableContent,
    )

    // Debounced content change handler
    const debouncedContentChange = useDebounce(handleContentChange, 1000)

    const handleChange = React.useCallback(
      (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
        setLocalContent(e.target.value) // Immediate update
        debouncedContentChange(e.target.value) // Debounced update
      },
      [debouncedContentChange],
    )

    return value.sub?.archived === false ? (
      <tr className="border-b border-gray-300" key={index}>
        <td>
          {/* <Checkbox
              onClick={(e) => {
                e.stopPropagation()
                changeCheckStatusData({ ...value, id: index })
              }}
              checked={isChecked}
              crossOrigin={undefined}
            /> */}
        </td>
        <td>{renderStatusIcon(value.platform)}</td>
        <td>
          <div className="py-2 mr-3 w-106">
            <Typography className="text-base text-lstnBlueGray-500 font-normal">
              @{value.user}
            </Typography>
            {value.contentTitle && (
              <Typography className="text-base text-lstnBlueGray-900 font-medium line-clamp-3">
                {value.contentTitle}
              </Typography>
            )}
            {value.contentText && (
              <Typography className="text-base text-lstnBlueGray-900 font-normal line-clamp-3">
                {value.contentText}
              </Typography>
            )}
            {value.sub?.status == 'replied' ? (
              <div>
                <div className="flex items-center">
                  <span className="mr-1">
                    <img src={replyIcon} alt="" />
                  </span>
                  <Typography className="text-lstnBlueGray-100 text-sm font-normal">
                    Replied via {value?.sub?.reply_source || 'Chrome extension'}
                  </Typography>
                </div>
                <div className="bg-well_primary-50 py-2 px-3 rounded-md">
                  <Typography className="text-base text-well_primary-500 font-normal">
                    @{currentAccount.name || 'vitomrgt'}
                  </Typography>
                  <Typography className="text-base text-lstnBlueGray-900 font-normal line-clamp-2">
                    {Array.isArray(value.sub?.replies)
                      ? value.sub?.replies[1]?.reply ||
                        value.sub.replies[value.sub.replies.length - 1]
                      : undefined}
                  </Typography>
                </div>
              </div>
            ) : (
              value.sub?.shouldReply && (
                <div className="py-2">
                  <div className="flex items-center">
                    <span className="mr-1">
                      <SparklesIcon strokeColor="#9C47ED" />
                    </span>
                    <Typography className="text-lstnBlueGray-100 text-sm font-normal">
                      Suggested by{' '}
                      <span className="text-lstnBlueGray-500">WellShared</span>
                    </Typography>
                  </div>
                  <div className="bg-well_primary-50 py-2 px-3 rounded-md flex gap-2">
                    {editingIndex === index && localContent !== '' ? (
                      <>
                        <Textarea
                          className="w-full"
                          labelProps={{
                            className: 'hidden',
                          }}
                          // rows={1}
                          ref={textAreaRef}
                          onChange={handleChange}
                          placeholder={''}
                          value={localContent}
                        />
                        <Button
                          onClick={() => handleSave(index, value)}
                          placeholder={undefined}
                          variant="text"
                          size="sm"
                          className="m-0 shadow-none hover:shadow-none p-0 self-start hover:bg-well_primary-50"
                          style={{ flexShrink: 0 }}
                        >
                          Save
                        </Button>
                      </>
                    ) : (
                      <>
                        <Typography className="text-base text-lstnBlueGray-900 font-normal">
                          {
                            value.sub?.autoReply.replyContent[
                              value.sub?.autoReply.replyContent.length - 1
                            ]
                          }
                        </Typography>
                        <Button
                          onClick={() =>
                            handleEditClick(
                              index,
                              value.sub?.autoReply.replyContent[
                                value.sub?.autoReply.replyContent.length - 1
                              ],
                            )
                          }
                          placeholder={undefined}
                          variant="text"
                          size="sm"
                          className="m-0 shadow-none hover:shadow-none p-0 self-start hover:bg-well_primary-50"
                          style={{ flexShrink: 0 }}
                        >
                          <img src={edit} alt="Edit Icon" className="h-4 w-4" />
                        </Button>
                      </>
                    )}
                  </div>
                </div>
              )
            )}
          </div>
        </td>
        <td>
          <div className="flex flex-wrap gap-2">
            {value.keywords?.map((word: string) => {
              return (
                <Typography
                  key={word}
                  className="p-1 border rounded-md border-well_primary-500 text-xs text-well_primary-500 font-sm"
                >
                  {word}
                </Typography>
              )
            })}
          </div>
        </td>
        <td>
          <div className="flex gap-2 items-center justify-center">
            <Typography className="text-base text-center text-lstnBlueGray-900 font-normal">
              {value?.sub && value.sub?.relevancyScore}
            </Typography>
          </div>
        </td>
        <td>
          <div className="flex gap-2 items-center">
            {value?.sub && value.sub?.status == 'replied' ? (
              <CheckCircle className="text-well_primary-500 iconSize20" />
            ) : (
              <CheckCircle className="text-[#CFD8DC] iconSize20" />
            )}
            <Typography className="text-base text-lstnBlueGray-900 font-normal">
              {value?.sub && value.sub?.status}
            </Typography>
          </div>
        </td>
        <td>
          <Typography className="text-base text-lstnBlueGray-900 font-normal">
            {new firebase.firestore.Timestamp(
              value.createdAt.seconds,
              value.createdAt.nanoseconds,
            )
              .toDate()
              .toLocaleString('en-US', {
                month: 'short',
                day: '2-digit',
                year: 'numeric',
              })}
          </Typography>
        </td>
        <td>
          <div className="flex items-center gap-4 ml-2">
            <CustomTooltip
              content="Open in New Tab"
              className="bg-lstnBlueGray-400"
            >
              <Button
                placeholder={undefined}
                variant="text"
                className="min-w-0 h-auto shadow-none hover:shadow-none p-0"
                onClick={() => {
                  console.log('value', value)
                  handleNavigate(
                    value.platform === 'facebook'
                      ? value.fbPostLink || ''
                      : value.platform === 'reddit'
                        ? value.rawData.url
                        : value.platform === 'instagram'
                          ? value.instaPostLink
                          : value.platformContentId,
                    value.platform,
                    value.user,
                    value.platformContentId,
                  )
                }}
              >
                <img src={navigateIcon} alt="Icon" />
              </Button>
            </CustomTooltip>
            <CustomMenu
              menuHandlerIcon={<MoreVertIcon />}
              menuitems={[
                {
                  label: t && t('socialReplies.mentions.hideMenuItem'),
                  itemClick: async () =>
                    await handleMenuItem(value.platformContentId),
                  icon: <VisibilityOffIcon />,
                },
              ]}
            />
          </div>
        </td>
        {/* Added all code for auto reply in one place if in future we want to remove it. It will be easy to comment or remove */}
        {hasAutoReply && (
          <td>
            <div className="items-center pr-3 hidden">
              <CustomTooltip
                content="Comment on reddit post by clicking Reply!!!"
                className="bg-lstnBlueGray-400"
              >
                <Button
                  placeholder={undefined}
                  variant="text"
                  disabled={
                    value?.sub?.status == 'replied' ||
                    reply ||
                    value.platform !== 'reddit'
                  }
                  className="min-w-0 h-auto shadow-none hover:shadow-none p-0 hover:bg-white"
                  onClick={async () => {
                    setReply(true)
                    Toast({
                      title: 'Replying...',
                      text: 'Please wait...',
                      variant: 'info',
                    })
                    if (value?.sub?.status == 'notReplied') {
                      const response = await autoGenerateReply({
                        accountId: currentAccount.id,
                        projectId: currentProject.id,
                        postId: value?.platformContentId,
                      })
                      if (response === 'Comment posted successfully!') {
                        Toast({
                          title: 'Reply posted successfully!',
                          variant: 'success',
                        })
                      } else {
                        Toast({
                          title: 'Reply failed!',
                          text: response,
                          variant: 'error',
                        })
                      }
                    }
                    setReply(false)
                  }}
                >
                  {t && t('socialReplies.mentions.reply')}
                </Button>
              </CustomTooltip>
            </div>
          </td>
        )}
      </tr>
    ) : null
  },
  areEqual,
)

MemoizedTableRow.displayName = 'MemoizedTableRow'
