import { database } from '../../firebaseApp'

export const addKeywordsToCollection = async (
  projectId: string,
  keyword: string,
): Promise<string> => {
  const keywordDocRef = database.collection('keywords').doc()
  const keywordId = keywordDocRef.id
  await keywordDocRef.set({
    id: keywordId,
    name: keyword,
    projectId,
  })
  return keywordId
}

export const removeKeywordFromCollection = async (
  keywordId: string,
): Promise<void> => {
  const keywordDocRef = database.collection('keywords').doc(keywordId)
  await keywordDocRef.delete()
}
