import React from 'react'
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
} from '@material-tailwind/react'

interface ISocialTabsProps {
  activeTab: string
  setActiveTab: (val: string) => void
  handleClick?: () => void
  data: {
    label: string
    value: string
    desc: JSX.Element
  }[]
}
const SocialTabs: React.FC<ISocialTabsProps> = ({
  activeTab,
  setActiveTab,
  data,
  handleClick,
}) => {
  return (
    <Tabs value={activeTab} className="z-0">
      <TabsHeader
        placeholder={undefined}
        className="rounded-none border-b border-blue-gray-50 bg-transparent p-0"
        indicatorProps={{
          className:
            'bg-transparent border-b-2 border-well_primary-500 shadow-none rounded-none my-3',
        }}
      >
        {data.map(({ label, value }) => (
          <Tab
            id={`social-tabs-${value}`}
            placeholder={undefined}
            key={value}
            value={value}
            onClick={() => {
              setActiveTab(value)
              handleClick && handleClick()
            }}
            className="text-lstnBlueGray-500 my-3 mt-8 w-fit px-3"
          >
            {label}
          </Tab>
        ))}
      </TabsHeader>
      <TabsBody placeholder={undefined}>
        {data.map(({ value, desc }) => (
          <TabPanel className="p-0 mt-8" key={value} value={value}>
            {desc}
          </TabPanel>
        ))}
      </TabsBody>
    </Tabs>
  )
}

export default SocialTabs
