import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { database } from '../../firebaseApp'
import {
  getFirestore,
  collection,
  query,
  where,
  orderBy,
  onSnapshot,
  doc,
  // getCountFromServer,
  Unsubscribe,
} from 'firebase/firestore'

const db = getFirestore()

export interface IMention {
  id: string
  sub?: {
    archived: boolean
    status: string
    replies: { reply: string } | string
    reply_source?: string
    relevancyScore?: number
    shouldReply?: boolean
    autoReply?: {
      platformCheck?: string
      prompt?: string
      relevancyCheck?: boolean
      replyContent?: string | string[]
      status?: string
    }
  }
  platformContentId: string
  platform: string
  user: string
  contentTitle: string
  contentText: string
  lastUpdatedAt: Date
  contentPostedAt: Date
  createdAt: { seconds: number; nanoseconds: number }
  contentMedia: {
    mediaType: string
    mediaUrl: string
  }
  keywords: string[]
  fbPostLink?: string
  instaPostLink?: string
  rawData?: any
}

// Keep track of active subscriptions
let mentionsUnsubscribe: Unsubscribe | null = null
const subCollectionUnsubscribes = new Map<string, Unsubscribe>()

export const fetchMentions = createAsyncThunk(
  'mentions/fetchMentions',
  async (data: { projectId: string; keywords: string[] }, thunkAPI) => {
    const { projectId, keywords } = data
    const { dispatch } = thunkAPI

    try {
      dispatch(setAllMentionLoading('pending'))

      // Cleanup existing listeners
      if (mentionsUnsubscribe) {
        mentionsUnsubscribe()
        mentionsUnsubscribe = null
      }
      subCollectionUnsubscribes.forEach((unsubscribe) => unsubscribe())
      subCollectionUnsubscribes.clear()

      if (!keywords?.length) {
        dispatch(setAllMentions([]))
        dispatch(setAllMentionLoading('succeeded'))
        return
      }

      const mentionsRef = collection(db, 'mentions')
      const mentionsQuery = query(
        mentionsRef,
        where('keywords', 'array-contains-any', keywords),
        orderBy('createdAt', 'desc'),
      )

      let currentMentions: IMention[] = []
      let initialDataLoaded = false

      // Function to setup subcollection listener
      const setupSubcollectionListener = (docData: any, mentionId: string) => {
        const subDocRef = doc(db, 'mentions', mentionId, 'projects', projectId)

        if (subCollectionUnsubscribes.has(mentionId)) {
          subCollectionUnsubscribes.get(mentionId)!()
        }

        const unsubscribe = onSnapshot(
          subDocRef,
          (subSnapshot) => {
            const subData = subSnapshot.exists() ? subSnapshot.data() : {}
            const mentionData: IMention = {
              ...docData.data(),
              sub: subData,
              id: mentionId,
            } as IMention

            const existingIndex = currentMentions.findIndex(
              (m) => m.id === mentionId,
            )

            if (existingIndex !== -1) {
              currentMentions[existingIndex] = mentionData
            } else {
              currentMentions.push(mentionData)
            }

            // Dispatch updated mentions immediately for realtime updates
            dispatch(setAllMentions([...currentMentions]))
          },
          (error) => {
            console.error(
              `Subcollection listener error for ${mentionId}:`,
              error,
            )
            dispatch(setError(error.message))
          },
        )

        subCollectionUnsubscribes.set(mentionId, unsubscribe)
      }

      mentionsUnsubscribe = onSnapshot(
        mentionsQuery,
        (snapshot) => {
          console.log('Processing mentions snapshot:', snapshot.size)

          snapshot.docChanges().forEach((change) => {
            const docData = change.doc
            const mentionId = docData.id

            if (change.type === 'removed') {
              currentMentions = currentMentions.filter(
                (m) => m.id !== mentionId,
              )
              if (subCollectionUnsubscribes.has(mentionId)) {
                subCollectionUnsubscribes.get(mentionId)!()
                subCollectionUnsubscribes.delete(mentionId)
              }
              dispatch(setAllMentions([...currentMentions]))
            } else {
              // Setup or update subcollection listener for this mention
              setupSubcollectionListener(docData, mentionId)
            }
          })

          // Set loading to succeeded after initial load
          if (!initialDataLoaded && snapshot.size >= 0) {
            initialDataLoaded = true
            dispatch(setAllMentionLoading('succeeded'))
          }
        },
        (error) => {
          console.error('Main mentions listener error:', error)
          dispatch(setError(error.message))
          dispatch(setAllMentionLoading('failed'))
        },
      )
    } catch (error) {
      console.error('fetchMentions error:', error)
      dispatch(setAllMentionLoading('failed'))
      throw error
    }
  },
)

// Add cleanup function
export const cleanupMentionsSubscriptions = () => {
  if (mentionsUnsubscribe) {
    mentionsUnsubscribe()
    mentionsUnsubscribe = null
  }
  subCollectionUnsubscribes.forEach((unsubscribe) => unsubscribe())
  subCollectionUnsubscribes.clear()
}

export const updateMentionsById = createAsyncThunk(
  'mentions/updateMentionsById',
  async (data: { id: string; projectId: string }) => {
    try {
      const { id, projectId } = data
      const mention = database.collection('mentions').doc(id)
      const mentionSnapshot = await mention.get()
      if (mentionSnapshot.exists) {
        const project = mention.collection('projects').doc(projectId)
        await project.update({ archived: true })
      } else {
        throw new Error('Mention does not exist')
      }
    } catch (error) {
      console.log('Error updating mention by id:', error)
      throw error
    }
  },
)

export const updateMentionSubCollection = createAsyncThunk(
  'mentions/updateMentionSubCollection',
  async (data: { id: string; projectId: string; sub: any }) => {
    try {
      const { id, projectId, sub } = data
      const mention = database.collection('mentions').doc(id)
      const mentionSnapshot = await mention.get()
      if (mentionSnapshot.exists) {
        const project = mention.collection('projects').doc(projectId)
        await project.update(sub)
      } else {
        throw new Error('Mention does not exist')
      }
    } catch (error) {
      console.log('Error updating mention sub collection:', error)
      throw error
    }
  },
)

interface IMentionState {
  allMentions: IMention[]
  loading: 'idle' | 'pending' | 'succeeded' | 'failed'
  error: string | null | undefined
  allMentionLoading: 'idle' | 'pending' | 'succeeded' | 'failed'
  allMentionError: string | null | undefined
  mention: IMention | {}
}

const initialState: IMentionState = {
  allMentions: [],
  loading: 'idle',
  error: null,
  allMentionLoading: 'idle',
  allMentionError: null,
  mention: {},
}

const mentionSlice = createSlice({
  name: 'mentions',
  initialState,
  reducers: {
    setAllMentions: (state, action) => {
      state.allMentions = action.payload
    },
    setAllMentionLoading: (state, action) => {
      state.allMentionLoading = action.payload
    },
    setMention: (state, action) => {
      state.mention = action.payload
    },
    setError: (state, action) => {
      state.error = action.payload
      state.loading = 'failed'
    },
    clearError: (state) => {
      state.error = null
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMentions.pending, (state) => {
      state.allMentionLoading = 'pending'
      state.error = null
    })
    builder.addCase(fetchMentions.fulfilled, (state) => {
      // state.allMentionLoading = 'succeeded'
      state.error = null
    })
    builder.addCase(fetchMentions.rejected, (state, action) => {
      state.error = 'failed'
      state.error = action.error.message
    })
    builder.addCase(updateMentionsById.pending, (state) => {
      state.loading = 'pending'
    })
    builder.addCase(updateMentionsById.fulfilled, (state) => {
      state.loading = 'succeeded'
    })
    builder.addCase(updateMentionsById.rejected, (state, action) => {
      state.loading = 'failed'
      state.error = action.error.message
    })
  },
})

export const {
  setAllMentions,
  setAllMentionLoading,
  setMention,
  setError,
  clearError,
} = mentionSlice.actions
export default mentionSlice.reducer
